var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"btn",class:[
    {'btn-round': _vm.round},
    {'btn-block': _vm.block},
    {'btn-icon btn-fab': _vm.icon},
    ( _obj = {}, _obj[("btn-" + _vm.type)] = _vm.type, _obj ),
    ( _obj$1 = {}, _obj$1[("btn" + _vm.type)] = _vm.type, _obj$1 ),
    {'btngradient': _vm.gradient},
    ( _obj$2 = {}, _obj$2[("btn-" + _vm.size)] = _vm.size, _obj$2 ),
    {'btn-simple': _vm.simple},
    {'btn-link': _vm.link},
    {'disabled': _vm.disabled && _vm.tag !== 'button'},
    {'btnFill' : _vm.fill}
  ],attrs:{"type":_vm.tag === 'button' ? _vm.nativeType : '',"disabled":_vm.disabled},on:{"click":_vm.handleClick}},[_c('div',{staticClass:"buttonInner"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }